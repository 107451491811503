<template>
    <el-container id="app" style="overflow:hidden">
        <el-header class="header">
            <div>
                <i class="el-icon-s-home" style="margin-right: 5px"></i>
                <span>泰度网评管理平台</span>
            </div>
            <div>
                <el-button icon="el-icon-setting" type="primary" @click="announcement_dialog_visible=true">
                    设置公告
                </el-button>
                <el-button icon="el-icon-bell" @click="event_dialog_visible=true" type="primary">
                    事件管理
                </el-button>
                <el-dropdown class="header-user">
                    <el-avatar v-if="userInfo.avatarUrl!=''" 
                                :src="userInfo.avatarUrl" 
                                :size="35" shape="circle" fit="fill"
                                style="cursor:pointer"></el-avatar>
                    <el-avatar v-else icon="el-icon-user-solid" shape="circle" :size="35" style="cursor:pointer"></el-avatar>
                    <span class="header-user-name">{{userInfo.userName}}</span>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item @click.native="logout">登出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                
            </div>
        </el-header>
        <el-dialog :visible.sync="event_dialog_visible" @open="searchEvent(false)" append-to-body>
                <div class="inputGroup">
                    <el-input placeholder="输入关键词查询" v-model="searchData.keyWord"></el-input>
                    <el-button type="text" @click="searchEvent(false)">搜索</el-button>
                </div>
                <el-table :data="event_table" stripe >
                    <el-table-column prop="eventName" label="事件名称"></el-table-column>
                    <el-table-column prop="eventType" label="事件类型"></el-table-column>
                    <el-table-column prop="eventInfo" label="事件信息"></el-table-column>
                    <el-table-column prop="createTime" label="发生时间"></el-table-column>
                </el-table>
                <el-pagination layout="prev,pager,next"
                            :page-size.sync="global.PAGE_SIZE"
                            :total="total"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            >
                </el-pagination>
            </el-dialog>
            <el-dialog :visible.sync="announcement_dialog_visible" @open="openAnnouncement" append-to-body title="公告" width="40%">
                <p>{{announcement}}</p>
                <el-button @click="updateAnnouncement_dialog_visible=true">更新公告</el-button>
                <el-button @click="announcement_dialog_visible=false">确认</el-button>
            </el-dialog>
            <el-dialog :visible.sync="updateAnnouncement_dialog_visible"  append-to-body width="35%">
                <el-input placeholder="请输入公告" v-model="updater"></el-input><br><br>
                <el-button @click="updateAnnouncement">确认</el-button>
                <el-button @click="updateAnnouncement_dialog_visible=false">取消</el-button>
            </el-dialog>
    <el-container>
        <el-aside style="width:180px;">
            <el-menu :default-active="activeIndex" 
                     class="el-menu-vertical-demo" 
                     @select="handleSelect"
                     style="height: calc(100vh - 130px)"
            > 
            <el-menu-item index="/mission">
                <template slot="title">                   
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-icon-test5"></use>
                    </svg>
                    <span>网评管理</span>                 
                </template>
            </el-menu-item>
            <el-menu-item index="/script">
                <template slot="title"> 
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-icon-test"></use>
                    </svg>
                    <span>投稿管理</span>                
                </template>
            </el-menu-item>
            <el-menu-item index="/user">
                <template slot="title">                  
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-icon-test4"></use>
                    </svg>
                    <span>队伍管理</span>                   
                </template>
            </el-menu-item>
            <el-menu-item index="/kpi">
                <template slot="title">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-icon-test"></use>
                    </svg>
                    <span>绩效考核</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/statistics">
                <template slot="title">                  
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-chart-line"></use>
                    </svg>
                    <span>数据分析</span>
                </template>
            </el-menu-item>
            </el-menu>
        </el-aside>
        <el-main style="height:calc(100vh - 130px);overflow:scroll">
            <el-breadcrumb separator="/" style="margin-bottom: 15px">
                <el-breadcrumb-item v-for="item in routerList" :key="item.title"
                    :to="{path:item.path}">
                        {{item.title}}
                </el-breadcrumb-item>
            </el-breadcrumb>
            <router-view @updateUserInfo="updateUserInfo"></router-view>
        </el-main>
    </el-container>
        <div class="footer">
            <span>主办单位:泰州网络宣传中心（泰州市网络安全应急指挥中心、泰州市互联网违法和有害不良信息举报中心）</span>
            <el-link href="https://beian.miit.gov.cn/" style="color:#9499A0;font-size:10px" :underline="false" target="_blank">互联网ICP备案:苏ICP备08120664号</el-link><br>
            <span>Copyright 2021-2022 泰州网络宣传中心（泰州市网络安全应急指挥中心、泰州市互联网违法和有害不良信息举报中心）版权所有</span>
        </div>
    </el-container>
    
</template>

<script>
import "../../assets/css/table.css";
export default {
  name: "",
  data() {
    return {
      activeIndex: "/mission",
      routerList: [],
      fullPath: [],
      userInfo: {},
      event_dialog_visible: false,
      event_table: [],
      searchData: {
        keyWord: ""
      },
      currentPage: 1,
      total: 0,
      announcement: "",
      announcement_dialog_visible: false,
      updater: "",
      updateAnnouncement_dialog_visible: false
    };
  },
   mounted(){
    this.userInfo = this.global.getUserInfo()
    this.getBreadcrumb();
    this.activeIndex = '/'+this.fullPath[0]
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      this.$router.push(`${key}`);
    },
    getBreadcrumb() {
      console.log("route", this.$route);
      let fp = this.$route.fullPath.split("/");
      fp.shift();
      if (this.fullPath.length == 0 || this.fullPath[0] != fp[0]) {
        this.fullPath = fp;
        this.routerList = [
          { title: this.$route.meta.title, path: this.$route.path }
        ];
      } else if (fp[0] == this.fullPath[0]) {
        if (this.fullPath.length > fp.length) {
          while (this.fullPath.length >= fp.length) {
            this.fullPath.pop();
            this.routerList.pop();
          }
          this.fullPath.push(fp[fp.length - 1]);
          this.routerList.push({
            title: this.$route.meta.title,
            path: this.$route.path
          });
        } else if (this.fullPath.length < fp.length) {
          this.fullPath.push(fp[fp.length - 1]);
          this.routerList.push({
            title: this.$route.meta.title,
            path: this.$route.path
          });
        }
      }
    },
    updateUserInfo(userInfo) {
      console.log("trigger");
      let { name, avatarUrl } = userInfo;
      this.username = name;
      this.avatarUrl = avatarUrl;
      sessionStorage.removeItem("userInfo");
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    handleCurrentChange() {
      this.searchEvent(true);
    },
    searchEvent(isCurrentChange) {
      console.log(this.searchData);
      this.$axios
        .post("/api/admin_notify_event/list", {
          keyWord: this.searchData.keyWord,
          pageNo: this.currentPage - 1,
          pageSize: this.global.PAGE_SIZE
        })
        .then(res => {
          console.log(res);
          if (!isCurrentChange) this.currentPage = 1;
          this.event_table = res.data.data.content;
          this.total = res.data.data.totalElements;
        })
        .catch(err => {
          console.log(err.response);
        });
    },
    openAnnouncement() {
      if (this.announcement == "") {
        this.$axios.get(`/api/config/announcement`).then(res => {
          console.log(res);
          this.announcement = res.data.data;
        });
      }
    },
    updateAnnouncement() {
      this.$axios
        .put(`/api/config/announcement?announcement=${this.updater}`)
        .then(res => {
          console.log(res);
          this.announcement = this.updater;
          this.updater = "";
          this.$message.success("更新成功！");
          this.updateAnnouncement_dialog_visible = false;
        })
        // .catch(err => {
        //   console.log(err.response);
        //   this.$message.error(err.response.data.message);
        // });
    },
    logout() {
      sessionStorage.removeItem("userInfo");
      this.$router.push("/login");
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  }
};
</script>

<style scoped>
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.footer {
        position: fixed;
        left:50%;
        bottom: 30px;
        height: 50px;
        transform: translate(-50%,0);

    }
    .footer span{
        color:#9499A0;
        font-size: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
</style>

<style src='../../assets/css/header.css' scoped>
</style>

